import React, { useState, useEffect } from 'react';
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope, FaInstagram, FaYelp } from 'react-icons/fa';
import { motion } from 'framer-motion';
import bg1 from '../assets/bg1.jpg';
import bg2 from '../assets/bg2.jpg';
import bg3 from '../assets/bg3.jpg';
import { FaShareNodes } from 'react-icons/fa6';
import { SiNextdoor } from 'react-icons/si';
const images = [bg1, bg2, bg3]; 

const Contact = () => {
  const [bgImage, setBgImage] = useState(images[0]);
  const [bgIndex, setBgIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex(prevIndex => {
        const newIndex = (prevIndex + 1) % images.length;
        setBgImage(images[newIndex]);
        return newIndex;
      });
    }, 3000); 

    return () => clearInterval(interval);
  }, []);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Cedar Carpentry",
          text: 'Check out Cedar Carpentry for all your construction needs!',
          url: window.location.href,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  return (
    <div className="text-center">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#120309] bg-opacity-50 flex items-center justify-center flex-col text-center">
          <h1 className="text-[#dc0e0e] text-4xl font-bold mb-4 mt-12">Contact Me</h1>
          <motion.div
            whileHover={{ 
              scale: 1.1, 
              translateZ: 50, 
              perspective: 1000, 
              boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.4)', 
              transition: { duration: 0.2 }
            }}
            className="inline-block mb-4"
          >
            <a href="tel:+14099324028" className="bg-[#14121F] text-[#dc0e0e] px-4 py-2 rounded font-bold">
              <span>📞</span><span>(409) 932-4028</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl text-center underline underline-offset-2 font-bold mb-4">Contact Me:</h2>
        <div className="flex flex-col items-center space-y-4 mb-8">
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{ 
              scale: 1.1, 
              transition: { duration: 0.3 } 
            }}
          >
            <FaPhoneAlt className="text-[#14121F]" style={{ verticalAlign: 'middle' }} />
            <a
              href="tel:+14099324028"
              className="bg-[#dc0e0e] text-[#14121F] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: '500px' }}
            >
              409-932-4028
            </a>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{ 
              scale: 1.1, 
              transition: { duration: 0.3 } 
            }}
          >
            <FaShareNodes className="text-[#14121F]" style={{ verticalAlign: 'middle' }} />
            <a
              href="#"
              onClick={handleShare}
              className="bg-[#dc0e0e] text-[#14121F] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: '500px' }}
            >
              Share this website
            </a>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{ 
              scale: 1.1, 
              transition: { duration: 0.3 } 
            }}
          >
            <FaMapMarkerAlt className="text-[#14121F]" style={{ verticalAlign: 'middle' }} />
            <p className="text-lg">820 153rd Ave NE Bellevue, WA 98007</p>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{ 
              scale: 1.1, 
              transition: { duration: 0.3 } 
            }}
          >
            <FaEnvelope className="text-[#14121F]" style={{ verticalAlign: 'middle' }} />
            <a
              href="mailto:allcitybuilders2004@gmail.com"
              className="bg-[#dc0e0e] text-[#14121F] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: '500px' }}
            >
              allcitybuilders2004@gmail.com
            </a>
          </motion.div>
        </div>
        <div className="flex justify-center space-x-4">
          <a href="https://www.instagram.com/adam.hebert.409/" target="_blank" rel="noreferrer" className="text-[#14121F] hover:text-[#dc0e0e]">
            <FaInstagram size={40} />
          </a>
          <a href="https://nextdoor.com/pages/cedar-carpentry-bellevue-wa/" target="_blank" rel="noreferrer" className="text-[#14121F] hover:text-[#dc0e0e]">
            <SiNextdoor size={40} />
          </a>
          <a href="https://www.yelp.com/biz/cedar-carpentry-bellevue" target="_blank" rel="noreferrer" className="text-[#14121F] hover:text-[#dc0e0e]">
            <FaYelp size={40} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
