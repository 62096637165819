import React from 'react';
import { FaEnvelope, FaInstagram } from 'react-icons/fa';
import { SiNextdoor } from 'react-icons/si';

const Footer = () => {
    return (
        <footer className="bg-[#dc0e0e] text-[#120309] py-4">
            <div className="container mx-auto px-4">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                    <div className="text-center sm:text-left mb-4 sm:mb-0">
                        <p>&copy; 2024 Cedar Carpentry</p>
                        <p>Phone: (409) 932-4028</p>
                    </div>
                    <div className="flex space-x-4">
                        <a href="mailto:allcitybuilders2004@gmail.com" className="hover:text-gray-200">
                            <FaEnvelope size={24} />
                        </a>
                        <a href='https://www.instagram.com/adam.hebert.409/' target='_blank' rel='noreferrer' className="hover:text-gray-200">
                            <FaInstagram size={24} />
                        </a>
                        <a href='https://nextdoor.com/pages/cedar-carpentry-bellevue-wa/' target='_blank' rel='noreferrer' className="hover:text-gray-200">
                            <SiNextdoor size={24} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
