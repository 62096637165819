import React, { useState, useEffect } from "react";
import certificate from "../assets/certificate.pdf";
import scheduling from "../assets/scheduling.pdf";
import cost from "../assets/cost.pdf";
import { motion } from "framer-motion";
import { FaFileDownload } from "react-icons/fa"; 
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import profileImage from "../assets/profile.jpg"; 

const images = [bg1, bg2, bg3];

const About = () => {
  const [bgImage, setBgImage] = useState(images[0]);
  const [bgIndex, setBgIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setBgImage(images[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#120309] bg-opacity-50 flex items-center justify-center flex-col text-center">
          <h1 className="text-[#dc0e0e] text-4xl font-bold mb-4 mt-12">
            About Me
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+14099324028"
              className="bg-[#14121F] text-[#dc0e0e] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>(409) 932-4028</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8 flex flex-col md:flex-row items-start">
        <div className="w-full md:w-1/2">
          <img
            src={profileImage}
            alt="Profile"
            className="rounded shadow-md w-full"
          />
        </div>
        <div className="w-full md:w-1/2 text-[#dc0e0e] text-xl mt-4 md:mt-0 md:pl-8 text-left">
          <p className="pb-5">
            <span className="text-[#120309] underline underline-offset-2 font-bold">
              About Adam:
            </span>{" "}
            I began my construction career in an electricians apprenticeship
            program here in the Seattle area back in 1999. I then relocated to
            South East Texas where I got involved in repairs and rebuilds of
            post severe storm damage and destruction. This is about the time
            where I made carpentry my specialty trade. After employment in
            production framing as well as Finish carpentry I found my way back
            to my roots here in the Pacific Northwest to open a business of my
            own. With 20 plus years of experience to offer this community, I
            deliver only high-end, flawless results, always maintaining passion
            for professionalism.
            <br />
            <br />
            Recently, I have also multiple certificate programs through Columbia
            University in the field of Construction.
          </p>
          <motion.a
            href={certificate}
            download
            whileHover={{ scale: 1.1 }}
            className="inline-flex items-center bg-[#14121F] text-[#dc0e0e] px-4 py-2 rounded font-bold mt-4 text-sm"
          >
            <FaFileDownload className="mr-2" /> Download Construction Project
            Management Certificate
          </motion.a>
          <motion.a
            href={scheduling}
            download
            whileHover={{ scale: 1.1 }}
            className="inline-flex items-center bg-[#14121F] text-[#dc0e0e] px-4 py-2 rounded font-bold mt-4 text-sm"
          >
            <FaFileDownload className="mr-2" /> Download Construction Scheduling
            Certificate
          </motion.a>
          <motion.a
            href={cost}
            download
            whileHover={{ scale: 1.1 }}
            className="inline-flex items-center bg-[#14121F] text-[#dc0e0e] px-4 py-2 rounded font-bold mt-4 text-sm  "
          >
            <FaFileDownload className="mr-2" /> Download Construction Cost
            Estimating and Cost Control Certificate
          </motion.a>
        </div>
      </div>
    </div>
  );
};

export default About;
